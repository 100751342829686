import { useEffect, useState } from "react";
import _ from "lodash";

import { formatTime  } from "../../utils";

const PuzzleTimer = ({ timerInterval, setTimerInterval, savedTiming, unsavedStartTime }) => {
    const [timerRefreshTime, setTimerRefreshTime] = useState(+new Date()); // Just used in display time calculation to force a rerender. Redundant

    const totalTimeOnPuzzle = _.parseInt(((savedTiming || 0) + (timerRefreshTime - unsavedStartTime)) * 0.001);

    useEffect(() => {
        if(!timerInterval) {
            const ti = setInterval(() => {
              setTimerRefreshTime(+new Date());
            }, 1000);
            setTimerInterval(ti);
        }

        return (() => {
            if(timerInterval) {
              clearInterval(timerInterval);
              setTimerInterval(null);
            }
        });
    });

    return formatTime(totalTimeOnPuzzle);
};

export default PuzzleTimer;