import React, {useEffect, useState} from "react";
import {
  createBrowserRouter, Outlet,
  RouterProvider,
} from "react-router-dom";
import '@material-design-icons/font/filled.css';

import {BACKEND_HOST, getQueryStringParam, LOGIN_STATES, removeQueryStringParam} from "./utils";
import axios from "axios";
import CryptoquipPuzzle from "./pages/cryptoquip-puzzle";
import HomePage from "./pages/home";
import Header from "./components/header";

export const AuthContext = React.createContext({
  userDetails: {
    email: '',
    first_name: '',
    id: '',
    image: '',
    lang: '',
    last_name: '',
    orgs: '',
    selectedOrg: '',
  },
});

const AppWrapper = () => {
  const [token, setToken] = useState(getQueryStringParam('t') || localStorage.getItem('token'));
  const [userDetails, setUserDetails ] = useState(null);
  const [ loginState, setLoginState ] = useState(LOGIN_STATES.INITIAL);

  useEffect(() => {
    localStorage.setItem('token', token);
    removeQueryStringParam('t');
    removeQueryStringParam('l');

    if(!token) {
      // Don't check for user details from backend if there's no token
      setUserDetails(null);
      setLoginState(LOGIN_STATES.LOGGED_OUT);
      return;
    }

    const getUserDetails = async () => {
      setLoginState(LOGIN_STATES.PROGRESS);
      try {
        const { data: { data: backendUserDetails }} = await axios({
          method: 'GET',
          url: '/user/get-details',
          baseURL: BACKEND_HOST,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if(backendUserDetails) {
          const { email, first_name, id, image, lang, last_name, orgs, email_verified } =
          backendUserDetails;
          setUserDetails({
            email,
            first_name,
            id,
            image,
            lang,
            last_name,
            orgs,
            email_verified,
          });
          setLoginState(LOGIN_STATES.LOGGED_IN);
        } else {
          setLoginState(LOGIN_STATES.LOGGED_OUT);
          localStorage.removeItem('token');
          setToken("");
        }
      } catch(e) {
        setLoginState(LOGIN_STATES.LOGGED_OUT);
        localStorage.removeItem('token');
        setToken("");
      }
    };

    getUserDetails();

  }, [token]);

  const contextValue = { userDetails, token };

  if(loginState === LOGIN_STATES.PROGRESS || loginState === LOGIN_STATES.INITIAL) {
    return null;
  }

  if(!!token && !userDetails) {
    return null;
  }

  // if(loginState === LOGIN_STATES.INVALID_TOKEN) {
  //   return (
  //     <div id="nopermission">
  //       <div>
  //         <img src={Logo} alt="Asoko logo" />
  //         <h3>Please login to view this page</h3>
  //         <p>This page is only accessible to authorised users.<br />
  //           Please click the button below to authenticate and login</p>
  //       </div>
  //       <button className="btn primary mt-4" onClick={() => { setToken(''); }}>Login</button>
  //     </div>
  //   );
  // }
  //
  // if(loginState !== LOGIN_STATES.LOGGED_IN) {
  //   return null;
  // }

  return (
    <AuthContext.Provider value={contextValue}>
      <div id="app-wrapper">
        <Header setToken={setToken} loginState={loginState} />
        <Outlet />
      </div>
    </AuthContext.Provider>
  )
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppWrapper />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "cryptoquip/:puzzle_id",
        element: <CryptoquipPuzzle />,
      },
    ],
  },
]);

const RouterWrapper = () => (
  <RouterProvider router={router} />
);

export default RouterWrapper;
