import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import {Link, useParams} from "react-router-dom";
import axios from "axios";

import PuzzleTimer from "./timer";

import { AuthContext } from "../../app";
import {BACKEND_HOST, MAX_PUZZLES, sanitisePuzzleText, reverseSubstituteLetters, formatTime, API_CALL_STATUSES, LETTERS } from "../../utils";
import PuzzleWord from "../../components/puzzle-word";



const CryptoquipPuzzle = () => {
  const { token } = useContext(AuthContext);
  const { puzzle_id } = useParams();
  const puzzleId = _.parseInt(puzzle_id);

  const [puzzleText, setPuzzleText] = useState([]);
  const [puzzleAuthor, setPuzzleAuthor] = useState([]);
  const [puzzleDetails, setPuzzleDetails] = useState(null);
  const [solution, setSolution] = useState([]);
  const [letterSubstitutions, setLetterSubstitutions] = useState({});
  const [saveStatus, setSaveStatus] = useState(API_CALL_STATUSES.INITIAL);
  const [fetchStatus, setFetchStatus] = useState(API_CALL_STATUSES.INITIAL);
  const [leaderboard, setLeaderboard] = useState([]);

  const [timerInterval, setTimerInterval] = useState(null);
  const [savedTiming, setSavedTiming] = useState(+new Date()); // Track the progress time till the last save in milliseconds
  const [unsavedStartTime, setUnsavedStartTime] = useState(+new Date()); // Timestamp of last save epoch milliseconds

  const isPuzzleCompleted = (newSubmissions) => {
    return _.toLower(_.join(solution)) === _.toLower(_.join(_.map(LETTERS, l => newSubmissions ? newSubmissions[l] : letterSubstitutions[l] || "")));
  }

  const saveProgressApiCall = async (letterSubstitutions) => {
    if(!token || !puzzleDetails) {
      return;
    }

    if(saveStatus === API_CALL_STATUSES.PROGRESS) {
      return;
    }

    setSaveStatus(API_CALL_STATUSES.PROGRESS);
    const startTime = +new Date();
    const newTiming = _.parseInt(((savedTiming || 0) + (startTime - unsavedStartTime)) * 0.001);
    const { data: { data: {
      leaderboard: backendLeaderboard,
    }}} = await axios.post(
      `${BACKEND_HOST}/cryptoquip/${puzzleDetails.pid}/save`, {
        time: newTiming,
        answer: _.map(LETTERS, l => letterSubstitutions[l] || ""),
        completed: isPuzzleCompleted(letterSubstitutions),
        pagePuzzleId: puzzleId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    setUnsavedStartTime(startTime);
    setSavedTiming(newTiming * 1000);
    setSaveStatus(API_CALL_STATUSES.SUCCESS);
    if(backendLeaderboard) {
      setLeaderboard(backendLeaderboard);
    }
  };

  useEffect(() => {
    if(!puzzleId) {
      return;
    }

    const getPuzzleDetails = async () => {
      setFetchStatus(API_CALL_STATUSES.PROGRESS);
      const { data: { data: {
        puzzleDetails,
        userProgress: backendUserProgress,
        leaderboard: backendLeaderboard,
      }}} = await axios.get(
        `${BACKEND_HOST}/cryptoquip/${puzzleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const {
        text,
        author_text,
        solution
      } = puzzleDetails;
      const pt = _.map(sanitisePuzzleText(text), word => reverseSubstituteLetters(word, solution));
      const at = _.map(sanitisePuzzleText(author_text), word => reverseSubstituteLetters(word, solution));
      setPuzzleText(pt);
      setPuzzleAuthor(at);
      setPuzzleDetails(puzzleDetails);
      setSolution(solution);
      setSavedTiming(backendUserProgress.time * 1000 || 0);
      setUnsavedStartTime(+new Date());
      setLeaderboard(backendLeaderboard);
      const resetSol = resetEntries(solution, pt, at);

      const userSubstitutions = {};
      _.each(backendUserProgress.answer, (an, index) => {
        userSubstitutions[LETTERS[index]] = _.trim(an) || "";
      });
      setLetterSubstitutions({
        ...resetSol,
        ...userSubstitutions,
      });
      setFetchStatus(API_CALL_STATUSES.SUCCESS);
    };

    getPuzzleDetails();
  }, [puzzleId, token]);

  const isCompleted = isPuzzleCompleted();


  const resetEntries = (solution, puzzleText, authorText) => {
    const containedLetters = _.filter(
      _.uniq(_.union(_.split(puzzleText, ""), _.split(authorText, ""))),
      l => _.includes(LETTERS, l)
    );
    const substitutedAnswers = {};
    LETTERS.forEach((l, index) => {
      if(_.includes(containedLetters, l)) {
        substitutedAnswers[l] = "";
      } else {
        substitutedAnswers[l] = solution[index];
      }
    });
    return substitutedAnswers;
  }

  const focusNextInput = (e) => {
    const letterInputs = document.querySelectorAll(".letter input");
    const currentIndex = Array.from(letterInputs).indexOf(e.target);
    const indexToHighlight = currentIndex + 1;
    if(letterInputs[indexToHighlight]) {
      letterInputs[indexToHighlight].focus();
    }
  }

  const focusPreviousInput = (e) => {
    const letterInputs = document.querySelectorAll(".letter input");
    const currentIndex = Array.from(letterInputs).indexOf(e.target);
    const indexToHighlight = currentIndex - 1;
    if(letterInputs[indexToHighlight]) {
      letterInputs[indexToHighlight].focus();
    }
  }

  const onLetterInput = (e, letter, cursorPosition) => {
    if(isCompleted) {
      return;
    }

    if(e.nativeEvent.data === null) {
      // Backspace pressed. Handled in keyUp event
      return;
    }

    const enteredValue = _.toLower(e.target.value) || "";
    const inputBeforeCursor = _.last(enteredValue.substring(0, cursorPosition || enteredValue.length))
    const newLetterSubstitutions = {
      ...letterSubstitutions,
      [letter]: inputBeforeCursor || "",
    };
    setLetterSubstitutions(newLetterSubstitutions);
    saveProgressApiCall(newLetterSubstitutions);

    if(!inputBeforeCursor) {
      return;
    }

    focusNextInput(e);
  }

  const onKeyUp = (e, letter) => {
    if(isCompleted) {
      return;
    }
    if(e.which === 8) {
      // Backspace  
      const isInputClear = !letterSubstitutions[letter];
      const newLetterSubstitutions = {
        ...letterSubstitutions,
        [letter]: "",
      };
      setLetterSubstitutions(newLetterSubstitutions);
      saveProgressApiCall(newLetterSubstitutions);
  
      if(isInputClear) {
        focusPreviousInput(e);
      }
    } else if(e.which === 37) {
      // Left arrow
      focusPreviousInput(e);
    } else if(e.which === 39) {
      // Right arrow
      focusNextInput(e);
    }
  };

  if(!puzzleText) {
    return null;
  }

  return (
    <div id="content-wrapper">
      <div id="puzzle-details-page-heading">
        <Link
          to={`/cryptoquip/${puzzleId - 1}`}
          disabled={puzzleId === 1}
        >
          <span className="material-symbols-outlined">
          first_page
          </span>
        </Link>
        <h4>
          Cryptoquip puzzle: {puzzleId}
        </h4>
        <Link
          to={`/cryptoquip/${puzzleId + 1}`}
          disabled={puzzleId === MAX_PUZZLES}
        >
          <span className="material-symbols-outlined">
          last_page
          </span>
        </Link>
      </div>

      {
        fetchStatus === API_CALL_STATUSES.PROGRESS ? (
          <div className="loader-wrapper">
              <div className="circle-loader"></div>
              <p>Loading...</p>
          </div>
        ) : (
          <>
            <div id="puzzle-details-options">
              {isCompleted ? formatTime(savedTiming * 0.001) : (
                <PuzzleTimer
                timerInterval={timerInterval}
                setTimerInterval={setTimerInterval}
                savedTiming={savedTiming}
                unsavedStartTime={unsavedStartTime}
                />
              )}
              <div className="flex-gap" />
              {
                !!token && (
                  <div>
                    {
                      saveStatus === API_CALL_STATUSES.PROGRESS && "Saving progress... "
                    }
                    {
                      saveStatus === API_CALL_STATUSES.SUCCESS && "Saved. "
                    }
                    {
                      isCompleted && "Congratulations!"
                    }
                  </div>
                )
              }
              {
                !isCompleted && (
                  <button className="pdo-btn small-btn" onClick={() => {
                    const resetAnswers = resetEntries(solution, puzzleText, puzzleAuthor);
                    setLetterSubstitutions(resetAnswers);
                    saveProgressApiCall(resetAnswers);
                  }}>
                    <span className="material-symbols-outlined">
                      restart_alt
                    </span>
                  </button>
                )
              }
            </div>

            <div className="puzzle-line">
              {
                _.map(puzzleText, (p, index) => (
                  <PuzzleWord
                    key={`${p}-${index}`}
                    word={p}
                    onLetterInput={onLetterInput}
                    onKeyUp={onKeyUp}
                    letterSubstitutions={letterSubstitutions}
                  />
                ))
              }
            </div>
            {
              _.size(puzzleAuthor) > 0 && (
                <div className="puzzle-line right">
                  <div className="letter">
                    -<br/>-
                  </div> {
                  _.map(puzzleAuthor, (p, index) => (
                    <PuzzleWord
                      key={`${p}-${index}`}
                      word={p}
                      onLetterInput={onLetterInput}
                      onKeyUp={onKeyUp}
                      letterSubstitutions={letterSubstitutions}
                    />
                  ))
                }
                </div>
              )
            }

            <div id="puzzle-leaderboard">
              <h4>Leaderboard</h4>
              {
                _.size(leaderboard) > 0 ? (
                  <div>
                    <div className="leaderboard-row bold">
                      <div className="lb-name">Name</div>
                      <div>Time</div>
                    </div>
                    {
                      _.map(leaderboard, l => (
                        <div className="leaderboard-row" key={l.user_id}>
                          <div className="lb-name">{l.first_name}</div>
                          <div>{formatTime(l.time)}</div>
                        </div>
                      ))
                    }
                  </div>
                ) : <div id="leaderboard-empty">
                  <i>No leaderboard scores available</i>
                </div>
              }
            </div>
          </>
        )
      }
    </div>
  );
}

export default CryptoquipPuzzle;
