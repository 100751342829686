import _ from "lodash";

export const BACKEND_HOST = window.location.hostname === "localhost" ? "http://localhost:3001" :'https://backend.euclidxr.com';

export const getQueryStringParam = (sParam) => {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');

  for (let i = 0; i < sURLVariables.length; i++) {
    const sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1];
    }
  }
};

export const removeQueryStringParam = (paramName) => {
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.delete(paramName);
  window.history.replaceState({}, document.title, newUrl.href);
}

export const setQueryStringParam = (paramName, paramValue) => {
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.set(paramName, paramValue);
  window.history.replaceState({}, document.title, newUrl.href);
}

export const LOGIN_STATES = {
  INITIAL: 'INITIAL',
  PROGRESS: 'PROGRESS',
  NO_TOKEN: 'NO_TOKEN',
  INVALID_TOKEN: 'INVALID_TOKEN',
  LOGGED_IN: 'LOGGED_IN',
  LOGGED_OUT: 'LOGGED_OUT',
  ERROR: 'ERROR',
};

export const API_CALL_STATUSES = {
  INITIAL: 'INITIAL',
  PROGRESS: 'PROGRESS',
  SUCCESS: "SUCCESS",
  ERROR: 'ERROR',
};

export const MAX_PUZZLES = 1664;
export const ITEMS_PER_PAGE = 24;
export const TOTAL_PAGES = Math.ceil(MAX_PUZZLES/ITEMS_PER_PAGE);
export const IS_DEV = process.env.NODE_ENV === 'development';

export const sanitisePuzzleText = (text) => {
  const lowerCase = _.toLower(text);
  return _.compact(_.split(lowerCase, " "));

}

export const LETTERS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

export const reverseSubstituteLetters = (text, solutionArray) => {
  const solutionMap = {};
  _.each(LETTERS, (l, index) => {
    solutionMap[l] = solutionArray[index];
  });
  const letterArray = _.map(_.split(text, ""), t => {
    if(_.includes(LETTERS, t)) {
      return _.findKey(solutionMap, s => s === t);
    }
    return t;
  });
  return letterArray.join("");
}

export const formatTime = (timeInSeconds) => {
  const minutes = _.parseInt(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
}