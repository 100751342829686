import React, { useContext } from "react";
import {Link} from "react-router-dom";
import Logo from "../assets/exr_logo.jpg";
import {IS_DEV} from "../utils";
import { AuthContext } from "../app";

const Header = ({ setToken }) => {
  const { userDetails } = useContext(AuthContext);
  return (
    <>
      <div id="header">
        <Link to="/" id="header-logo">
          <img src={Logo} alt="Asoko Logo" id="header-logo-img"/>
        </Link>
        <div>
          {
            userDetails ? (
              <>
                <span id="header-name">{userDetails.first_name} {userDetails.last_name}</span>
                <button id="header-logout" className="btn primary" onClick={() => {
                  setToken(null);
                }}>
                  Logout
                </button>

              </>
            ) : (
              <a id="header-login" className="btn primary" href={`https://auth.euclidxr.com/login/?s=${IS_DEV ? 'l3' : 'p'}`}>
                Login
              </a>
            )
          }
        </div>

      </div>

    </>
  )
}


export default Header;
