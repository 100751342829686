import React from "react";
import _ from "lodash";
import {LETTERS} from "../utils";

const PuzzleWord = ({
  word,
  onKeyUp,
  letterSubstitutions,
  onLetterInput
}) => {
  return (
    <div className="puzzle-word">{
      _.map(word, (letter, index) => {
        if(!_.includes(LETTERS, letter)) {
          return (
            <div className="letter" key={`${letter}-${index}`}>
              {letter}<br/>{letter}
            </div>
          );
        }
        return (
          <div className="letter" key={`${letter}-${index}`}>
            {letter}<br/>
            <input
              value={letterSubstitutions[letter] || ""}
              onKeyUp={(e) => {
                onKeyUp(e, letter);
              }}
              onChange={(e) => {
                onLetterInput(e, letter, e.target.selectionStart);
              }}
            />
          </div>
        );
      })
    }</div>
  );
};

export default PuzzleWord;
