import React, {useContext, useEffect, useState} from "react";
import _ from "lodash";
import { BACKEND_HOST, getQueryStringParam, ITEMS_PER_PAGE, MAX_PUZZLES, setQueryStringParam } from "../../utils";
import {Link} from "react-router-dom";
import axios from "axios";

import { AuthContext } from "../../app";

const PUZZLE_STATES = {
  pending: "pending",
  progress: "progress",
  completed: "completed",
};

const ITEM_ICONS = {
  [PUZZLE_STATES.pending]: (
    <span className="material-symbols-outlined">
      pending
    </span>
  ),
  [PUZZLE_STATES.completed]: (
    <span className="material-symbols-outlined">
      task_alt
    </span>
  ),
  [PUZZLE_STATES.progress]: (
    <span className="material-symbols-outlined">
      clock_loader_60
    </span>
  ),
};

const HomePage = () => {
  const { token } = useContext(AuthContext);
  const [selectedPage, setSelectedPage] = useState(_.parseInt(getQueryStringParam("page")) || 1);
  const [userProgress, setUserProgress] = useState(null);
  const [hideCompleted, setHideCompleted] = useState(localStorage.getItem("hide-comp") === "yes");

  useEffect(() => {
    localStorage.setItem("hide-comp", hideCompleted ? "yes" : "no");
  }, [hideCompleted]);

  useEffect(() => {
    setQueryStringParam("page", selectedPage);
  }, [selectedPage]);
  useEffect(() => {
    if(!token) {
      setUserProgress({});
      return;
    }
    const getUserProgress = async () => {
      const { data: { data } } = await axios.get(
        `${BACKEND_HOST}/cryptoquip/user-progress`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserProgress(data ? _.keyBy(data, "puzzle_id") : {});
    };

    getUserProgress();
  }, [token]);

  if(userProgress === null) {
    return null;
  }

  const puzzleNumbers = _.map(new Array(MAX_PUZZLES), (empty, index) => index + 1);
  const puzzleNumbersToDisplay = hideCompleted ? _.filter(puzzleNumbers, p => !userProgress[p]?.completed) : puzzleNumbers;
  const totalPages = Math.ceil(puzzleNumbersToDisplay.length/ITEMS_PER_PAGE);

  const puzzles_in_page = _.chunk(puzzleNumbersToDisplay, ITEMS_PER_PAGE)[selectedPage - 1];


  return (
    <div id="content-wrapper">
      <h4>Choose a puzzle</h4>
      <div id="puzzle-list-page-selection">
        <button
          onClick={e => setSelectedPage(1)}
          disabled={selectedPage === 1}
        >
          <span className="material-symbols-outlined">
          first_page
          </span>
        </button>
        <button
          onClick={e => setSelectedPage(selectedPage - 1)}
          disabled={selectedPage === 1}
        >
          <span className="material-symbols-outlined">
          chevron_left
          </span>
        </button>
        Page {selectedPage} of {totalPages}
        <button
          onClick={e => setSelectedPage(selectedPage + 1)}
          disabled={selectedPage === totalPages}
        >
        <span className="material-symbols-outlined">
          chevron_right
          </span>
        </button>
        <button
          onClick={e => setSelectedPage(totalPages)}
          disabled={selectedPage === totalPages}
        >
          <span className="material-symbols-outlined">
          last_page
          </span>
        </button>
      </div>
      <div id="puzzles-list-wrapper">
        {
          puzzles_in_page.map(p => {
            let puzzle_state = "";
            if(!userProgress[p]) {
              puzzle_state = PUZZLE_STATES.pending
            } else if(!userProgress[p].completed) {
              puzzle_state = PUZZLE_STATES.progress
            } else {
              puzzle_state = PUZZLE_STATES.completed;
            }
            return (
              <div className={`puzzle-list-item ${puzzle_state}`} key={p}>
                <Link className={`puzzle-item-inner ${puzzle_state}`} to={`/cryptoquip/${p}`}>
                  {ITEM_ICONS[puzzle_state]}
                  <p>{p}</p>
                </Link>
              </div>
            );
          })
        }
      </div>
      <input type="checkbox" checked={hideCompleted} onChange={() => setHideCompleted(!hideCompleted)} />&nbsp;
      <label>Hide completed puzzles</label>
    </div>
  );
};

export default HomePage;
